import _includes from 'core-js/library/fn/string/virtual/includes'
import _repeat from 'core-js/library/fn/string/virtual/repeat'
import _startsWith from 'core-js/library/fn/string/virtual/starts-with'
import _trimEnd from 'core-js/library/fn/string/virtual/trim-end'
import _find from 'core-js/library/fn/array/find'
import _from from 'core-js/library/fn/array/from'
import _finally from 'core-js/library/fn/promise/finally'
import _assign from 'core-js/library/fn/object/assign'

String.prototype.includes = String.prototype.includes || _includes
String.prototype.repeat = String.prototype.repeat || _repeat
String.prototype.startsWith = String.prototype.startsWith || _startsWith
String.prototype.trimEnd = String.prototype.trimEnd || _trimEnd
Array.prototype.find = Array.prototype.find || _find
Array.from = Array.from || _from
Promise.prototype.finally = Promise.prototype.finally || _finally
Object.assign = Object.assign || _assign
